var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("p", { staticClass: "font-weight-black " }, [
        _vm._v("\n    " + _vm._s(_vm.$t("title.contractor.tosNotes")) + "\n  ")
      ]),
      _vm._l(_vm.splitMessageLine(_vm.$t("description.tosNotes")), function(
        message,
        index
      ) {
        return _c("p", { key: index, staticClass: "ma-0 px-0 pb-3" }, [
          _vm._v("\n    " + _vm._s(message) + "\n  ")
        ])
      }),
      _c("p", { staticClass: "font-weight-black mt-4" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("title.contractor.tosPersonalInformation")) +
            "\n  "
        )
      ]),
      _c("p", { staticClass: "ma-0 pa-0" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("description.tosPersonalInformation")) +
            "\n  "
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }