<template>
  <span>
    <p class="font-weight-black ">
      {{ $t('title.contractor.tosNotes') }}
    </p>
    <p
      v-for="(message, index) in splitMessageLine($t('description.tosNotes'))"
      :key="index"
      class="ma-0 px-0 pb-3"
    >
      {{ message }}
    </p>
    <p class="font-weight-black mt-4">
      {{ $t('title.contractor.tosPersonalInformation') }}
    </p>
    <p class="ma-0 pa-0">
      {{ $t('description.tosPersonalInformation') }}
    </p>
  </span>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
export default {
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message.join('\n'));
    },
  },
};
</script>